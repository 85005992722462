@import 'shared';

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 px(20);
  box-shadow: $element-shadow;
  background-color: $white;
  @include z-index(main-nav);
  @include flex-center-vert;
  height: $navbar-height-mobile;

  @include media-desktopSm {
    height: $navbar-height-desktop;
  }

  .skipToContent {
    position: absolute;
    top: 0;
    left: 0;
    height: fit-content;
    pointer-events: none;
    opacity: 0.0001;
  }

  .skipToContent:focus,
  .skipToContent:active {
    color: $white;
    background-color: $black;
    opacity: 1;
  }

  > .wrapper {
    display: flex;
    max-width: px(1440);
    margin: 0 auto;
    width: 100%;

    > ul {
      flex-grow: 1;
      @include flex-center-vert;

      &.routes {
        justify-content: flex-start;

        .threeLogo {
          width: px(25);
        }
      }

      &.links {
        justify-content: flex-end;

        a {
          img {
            @include box(px(25));
          }
        }
      }

      > li {
        padding: 0 px(10);
      }
    }
  }
}
