// Globals
//
// Global styling, normalize overwritten
@import 'fonts';
@import 'polyfills';
@import 'shared';

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  text-size-adjust: none;
  font-size: 10px;
  text-align: center;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: px(16);
  word-break: keep-all;
  @include font-body;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  background-color: $page-bg-color;
  z-index: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  min-height: 100vh;
  padding-top: $navbar-height-mobile;
  @include z-index(pages);

  @include media-desktopSm {
    padding-top: $navbar-height-desktop;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

button,
input,
a {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-header;
}
